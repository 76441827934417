<template>
   <div v-if="isMounted">
      <big-title>
         Activar Ingreso <span>Pasivo</span>.
      </big-title>

      <vx-card title="">
         <div class="vx-row">
            <div class="vx-col xxl:w-3/4 w-full">
               <h2>Confirmar tu monto de inversión fija</h2>
               <p>
                  Determina el monto que deseas mantener como inversión fija, es decir, generando intereses (ingreso
                  pasivo).
               </p>
            </div>
         </div>

         <template>
            <div class="vx-row">
               <div class="vx-col w-full md:w-1/3 lg:w-1/4">
                  <div class="vx-row mt-4">
                     <div class="vx-col w-full">
                        <p class="bold">Inversión fija total</p>
                     </div>
                  </div>
                  <div class="vx-row mt-2">
                     <div class="vx-col w-full">
                        <vs-input class="investment-input w-full" type="text" v-model="fixedInvestmentMoney"
                           v-money="money" name="fixedInvestmentMoney" v-validate="'required'" :min="500"
                           icon-pack="feather" icon="icon-dollar-sign" autocomplete="off" placeholder="Monto a invertir" />
                     </div>
                  </div>
               </div>
            </div>

            <div v-if="!isFixedInvestmentValid" class="vx-row mt-6 ml-1 mr-1" style="background-color: #F1F1F2;">
               <div class="vx-col xxl:w-1/4 w-full mt-3 mb-3">
                  <vs-list>
                     <vs-list-header title="Inversión fija" />
                     <vs-list-item title="Actual:"
                        :subtitle="explicitMoneyFormat(this.user.total_portfolio, 0)" />
                     <vs-list-item title="Determinada:" :subtitle="requestedFixedInvestment" />
                     <vs-list-item title="Faltante:" :subtitle="explicitMoneyFormat(missingAmount, 0)">
                        <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-warning" />
                     </vs-list-item>
                  </vs-list>
               </div>
               <div class="vx-col xxl:w-3/4 w-full mt-3 mb-3">
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <div class="flex">
                           <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-warning" class="mr-3" />
                           <span class="bold mt-1">Existen dos maneras de alcanzar tu inversión fija
                              determinada:</span>
                        </div>
                     </div>
                  </div>
                  <div class="vx-row mt-6">
                     <div class="vx-col xxl:w-1/2 w-full">
                        <h5>Abona fondos</h5>
                        <p>
                           Deposita {{ explicitMoneyFormat(missingAmount, 0) }} (la diferencia) para alcanzar
                           la inversión fija determinada.<br><br>

                           Datos para depósito:<br>
                           Banco: STP (Sistema de Transferencias y Pagos)<br>
                           CLABE: {{ stpClabe }}<br>
                           Beneficiario: {{ user.complete_name }}<br>
                           Método de transferencia: SPEI<br>
                        </p>
                     </div>
                     <div class="vx-col xxl:w-1/2 w-full">
                        <h5>Reinvierte pagos</h5>
                        <p>
                           En caso de que ya tengas alguna inversión en tu cuenta, todos los pagos que recibas
                           se
                           reinvertirán automáticamente, hasta que tu inversión fija sea igual a la
                           determinada.
                           <br><br>
                           <b>De acuerdo con tu inversión actual, te tomaría aproximadamente {{ missingMonths
                           }} meses alcanzar
                              tu
                              inversión fija determinada.</b>
                        </p>
                     </div>
                  </div>

               </div>
            </div>
            <div v-else class="vx-row mt-6 ml-1 mr-1" style="background-color: #F1F1F2;">
               <div class="vx-col xxl:w-1/4 w-full mt-3 mb-3">
                  <vs-list>
                     <vs-list-header title="Inversión fija" />
                     <vs-list-item title="Actual:"
                        :subtitle="explicitMoneyFormat(this.user.total_portfolio, 0)" />
                     <vs-list-item title="Determinada:" :subtitle="requestedFixedInvestment" />
                     <vs-list-item title="Excedente:" :subtitle="explicitMoneyFormat(excessAmount, 0)" />
                  </vs-list>
               </div>
               <div class="vx-col xxl:w-3/4 w-full mt-3 mb-3">
                  <div class="vx-row mt-6">
                     <div class="vx-col w-full mt-base text-center">
                        <h5>¡Felicidades!</h5>
                        <p>
                           Tu cuenta ya tiene la inversión fija requerida para comenzar a generar ingresos
                           pasivos
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            <div class="vx-row mt-6">
               <div class="vx-col w-full">
                  <div class="flex">
                     <vs-button class="mt-6" type="border" @click="backToMain">Atrás</vs-button>
                     <vs-button @click.stop="activateInvestmentPlan"
                        class="ml-3 mt-6">Confirmar monto</vs-button>
                  </div>
               </div>
            </div>
         </template>
      </vx-card>
      
      <!-- DISCLAIMER INVERSION AUTOMATICA -->
      <automatic-investment-disclaimer/>
   </div>
</template>
<script>

import { mapState } from "vuex";
import { VMoney } from 'v-money'
import currencyHelper from "@mixins/currencyHelper";
import formatHelper from '@mixins/formatHelper';
import stpHelper from "@mixins/stpHelper";
import AutomaticInvestmentDisclaimer from "@components/investor/widgets/AutomaticInvestmentDisclaimer";

export default {
   name: "PassiveIncome",
   directives: { money: VMoney },
   mixins: [currencyHelper, formatHelper, stpHelper],
   components: {
      AutomaticInvestmentDisclaimer
   },
   data() {
      return {
         fixedInvestmentMoney: 0,
         isMounted: false,
         stpClabe: null,
         money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 0,
            masked: false
         },
      }
   },
   computed: {
      ...mapState("auth", ["roleGeneralData"]),
      isFixedInvestmentValid() {
         return this.user.total_portfolio >= this.unMoneyFormat(this.fixedInvestmentMoney);
      },
      missingAmount() {
         let total = this.unMoneyFormat(this.fixedInvestmentMoney) - this.user.total_portfolio;
         return total < 0 ? 0 : total;
      },
      excessAmount() {
         let total = this.user.total_portfolio - this.unMoneyFormat(this.fixedInvestmentMoney);
         return total < 0 ? 0 : total;
      },
      requestedFixedInvestment() {
         let amount = this.unMoneyFormat(this.fixedInvestmentMoney);
         return this.explicitMoneyFormat(amount, 0);
      },
      missingMonths() {
         let fixedInvestmentRequested = this.unMoneyFormat(this.fixedInvestmentMoney);
         let currentFixedInvestment = this.user.total_portfolio;
         let netYield = this.roleGeneralData.netYield;
         netYield = netYield > 0 ? (netYield / 100) + 1 : 0;
         let months = Math.round(this.getBaseLog((fixedInvestmentRequested / currentFixedInvestment), netYield) * 12);
         return months;
      }
   },
   async mounted() {
      this.isMounted = false;
      this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
      let userPassiveIncomeFixedInvestment = this.user.passive_income_fixed_investment;
      if (userPassiveIncomeFixedInvestment && userPassiveIncomeFixedInvestment > 0) {
         this.fixedInvestmentMoney = userPassiveIncomeFixedInvestment;
      }
      this.isMounted = true;
   },
   methods: {
      getBaseLog(x, base) {
         return Math.log(x) / Math.log(base);
      },
      async getPlansInfo() {
         try {
            const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
            this.investorPlan = res.data.plan;
            this.planWasActivated = (this.investorPlan.autoinvest_deposits === true || this.investorPlan.autoinvest_deposits === 1);
         }
         catch (e) {
         }
      },
      async goToInvestmentPrefs() {
         await this.$router.replace({ name: 'criteriosInversion' });
      },
      async backToMain() {
         await this.$router.replace({ name: 'planesInversion' });
      },
      async activateInvestmentPlan() {
         this.showLoading(true);
         try {
            let payload = {
               investment_plan: "passive_income",
               min_fixed_investment_portfolio: this.unMoneyFormat(this.fixedInvestmentMoney)
            };
            this.injectAccountMetadataToPayload(payload);
            await axios.post(`api/v2/investor/${this.InvestorId}/activateInvestmentPlan`, payload);
            this.successNotifDialog('Operación exitosa',
               'Has activado el ingreso pasivo.');
            await this.$router.replace({ name: 'opcionalRetiroRecurrente' });
         }
         catch (e) {
            let errorMssg = "No ha sido posible actualizar la información, intente más tarde.";
            if (e.response?.data) {
               errorMssg = e.response.data.message ? e.response.data.message : e.response.data;
            }
            this.errorNotifDialog('Error en guardado', errorMssg);
         }
         this.showLoading(false)
      }
   }
}
</script>
